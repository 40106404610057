import React, { useEffect, useState } from "react";
import {
  Grid,
  GridItem,
  Text,
  Input,
  Textarea,
  Box,
  Button,
  Stack,
  FormLabel,
  FormControl,
  CircularProgress,
} from '@chakra-ui/react';

import contacto from "../../administracion/jsons_images/Shared/Contacto/contacto"
import ModalSuccess from "./ModalSuccess"

const configHubspot = {
  portalId: process.env.REACT_APP_HUBSPOT_PORTALID,
  region: process.env.REACT_APP_HUBSPOT_REGION,
  formId: process.env.REACT_APP_HUBSPOT_FORMID
}

const initState = {
  contactName: "",
  contactLastName: "",
  email: "",
  phone: "",
  companyName: "",
  message: "",
}

export default function Contactanos() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState(initState);

  useEffect(() => {
    if (
      !formData.companyName ||
      !formData.contactLastName ||
      !formData.email ||
      !formData.phone ||
      !formData.message
    ) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }, [formData]);

  useEffect(() => {
    setTimeout(() => {
      setError(false)
    }, 8000);
  }, [error]);

  useEffect(() => {
    setFormData({
      ...formData,
      message: window.localStorage.getItem("plan") ? `Hola, me interesa el Plan ${window.localStorage.getItem("plan")}` : ""
    })
  }, [window.localStorage.getItem("plan")]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setLoading(true)
    const submit = async () => {
      try {
        var xhr = new XMLHttpRequest();
        var url = `https://api.hsforms.com/submissions/v3/integration/submit/${configHubspot.portalId}/${configHubspot.formId}`
        var data = {
          "fields": [
            {
              "name": "firstname",
              "value": formData.contactName
            },
            {
              "name": "lastname",
              "value": formData.contactLastName
            },
            {
              "name": "email",
              "value": formData.email
            },
            {
              "name": "mobilephone",
              "value": formData.phone
            },
            {
              "name": "company",
              "value": formData.companyName
            },
            {
              "name": "message",
              "value": formData.message
            },
          ],
          "context": {
            "pageUri": "www.audisoft.com",
            "pageName": "Audisoft web page"
          },
        }
        var final_data = JSON.stringify(data)

        xhr.open('POST', url);
        // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onreadystatechange = function () {
          if (xhr.readyState === 4 && xhr.status === 200) {
            setIsOpen(true)
            setFormData(initState)
            setLoading(false)
            // alert(xhr.responseText); // Returns a 200 response if the submission is successful.
          } else if (xhr.readyState === 4 && xhr.status === 403) {
            setError(true)
            alert(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.     
            setLoading(false)
          } else if (xhr.readyState === 4 && xhr.status === 404) {
            setError(true)
            alert(xhr.responseText); //Returns a 404 error if the formGuid isn't found    
            setLoading(false)
          }
        }
        // Sends the request 
        await xhr.send(final_data)
      } catch (error) {
        setLoading(false)
        setError(true)
      }
    }
    submit()
  }

  return (
    <Grid
      bgImage={{
        base: contacto.bg_m,
        lg: contacto.bg_w
      }}
      bgSize={{ base: "auto", md: "100%", lg: "100% auto" }}
      backgroundRepeat='no-repeat'
    >
      {isOpen && <ModalSuccess isOpen={isOpen} setIsOpen={setIsOpen} />}
      <Grid
        pt={{ base: "100%", sm: "100%", md: "400px", lg: "470px" }}
        pb={{ base: "100px" }}
        px={{ base: "5%", lg: "15%" }}
        templateColumns={{ base: "repeat(1)", sm: "repeat(1)", md: "repeat(2, 1fr)", lg: "repeat(2, 1fr)" }}
        templateRows={{ base: "repeat(2)", sm: "repeat(2)", md: "repeat(1, 1fr)", lg: "repeat(1, 1fr)" }}
        gap={3}
      >
        <GridItem
          px="5%"
          pt={{ base: "2%", md: "15%" }}
          colSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
          rowSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
        >
          <Grid
            display="inline"
            float="center"
          >
            <Text as="span" fontSize="30px" fontWeight={"bold"} pl="30px" as={"mark"} bgColor="#BEE625"> {contacto.titulo1}</Text>
            <Text as="span" fontSize="30px" fontWeight={"bold"}>{contacto.titulo2}</Text>
          </Grid>

          <Grid px="5%" py="4%">
            <Stack align={'flex-start'}>
              <Text fontSize="16px" color="#BEE625" fontWeight="bold">Ubicación</Text>
              <Text fontSize="12px">{contacto.ubicacion.calle}</Text>
              <Text fontSize="12px">{contacto.ubicacion.oficina} </Text>
              <Text fontSize="12px">{contacto.ubicacion.region}</Text>
            </Stack>
            <Stack pt="4%">
              <Text fontSize="16px" color="#BEE625" fontWeight="bold">Email: </Text>
              <Text fontSize="12px">{contacto.email}</Text>
            </Stack>
            <Stack pt="4%">
              <Text fontSize="16px" color="#BEE625" fontWeight="bold">Teléfono: </Text>
              <Text fontSize="12px">{contacto.telefono}</Text>
            </Stack>
          </Grid>
        </GridItem>
        <GridItem
          colSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
          rowSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
        >
          <Grid
            borderWidth="1px"
            borderColor={"#BEE625"}
            borderRadius={'xl'}
            w="90%"
            py="5%"
            background="linear-gradient(175deg, rgba(245,245,247,1) 19%, rgba(252,255,239,1) 89%)"
          >
            <Box
              textAlign="center"
              m="auto"
              pt="5%"
            >
              <Grid
                m="auto"
                w="60%"
                pb="5%"
              >
                <Text fontSize="12px" fontWeight={"bold"} color="#A5C918">
                  Si ya eres cliente inicia sesión
                </Text>
                <Text fontSize="12px">
                  para comunicarte directamente con nuestro equipo de soporte
                </Text>
              </Grid>
              <FormControl
                id="contact-form"
                px={{ base: "5%", lg: "10px" }}
                m="auto"
                onSubmit={handleSubmit}
              >
                <FormLabel fontSize="12px" fontWeight="bold" color="#A5C918">Nombre</FormLabel>
                <Input
                  aria-label="Nombre"
                  onChange={handleChange}
                  name="contactName"
                  value={formData.contactName}
                  bgColor="white"
                  border="1px"
                  borderRadius="20px"
                />
                <FormLabel fontSize="12px" fontWeight="bold" color="#A5C918">Apellido</FormLabel>
                <Input
                  aria-label="Apellido"
                  onChange={handleChange}
                  name="contactLastName"
                  value={formData.contactLastName}
                  bgColor="white"
                  border="1px"
                  borderRadius="20px"
                />
                <FormLabel fontSize="12px" fontWeight="bold" color="#A5C918">Email</FormLabel>
                <Input
                  aria-label="Email"
                  onChange={handleChange}
                  name="email"
                  value={formData.email}
                  bgColor="white"
                  border="1px"
                  borderRadius="20px"
                />
                <FormLabel fontSize="12px" fontWeight="bold" color="#A5C918">Número de teléfono móvil</FormLabel>
                <Input
                  aria-label="Telefono"
                  onChange={handleChange}
                  name="phone"
                  value={formData.phone}
                  bgColor="white"
                  border="1px"
                  borderRadius="20px"
                />
                <FormLabel fontSize="12px" fontWeight="bold" color="#A5C918">Nombre de la empresa</FormLabel>
                <Input
                  aria-label="Nombre Empresa"
                  onChange={handleChange}
                  name="companyName"
                  value={formData.companyName}
                  bgColor="white"
                  border="1px"
                  borderRadius="20px"
                />
                <FormLabel fontSize="12px" fontWeight="bold" color="#A5C918">Mensaje</FormLabel>
                <Textarea
                  aria-label="Mensaje"
                  onChange={handleChange}
                  name="message"
                  value={formData.message}
                  bgColor="white"
                  border="1px"
                  borderRadius="20px"
                />
                {error &&
                  <Text fontSize="12px" color="red.300">Error al enviar el formulario</Text>
                }
                <Grid pt="4%" >
                  <Button
                    border="2px"
                    borderRadius="15px"
                    borderColor="#A5C918"
                    variant="outline"
                    mx="auto"
                    disabled={disabled}
                    onClick={handleSubmit}
                  >
                    {!loading ?
                      <Text color="#B2D237"> Enviar</Text>
                      :
                      <CircularProgress isIndeterminate color="white" />
                    }
                  </Button>
                </Grid>
              </FormControl>
            </Box>
          </Grid>
        </GridItem>
      </Grid>
    </Grid >
  );
}
