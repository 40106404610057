// IMAGENES
import test01 from './bedecarratz_julio.jpg'
import test02 from './perfomaq_jocelyn.jpg'
import test03 from './dif03.svg'
import test04 from './dif03.svg'

//  VIDEOS
import video1 from './audisoft2.mov'
import video2 from './audisoft2.mov'

const compromiso = {
  titulo: 'No consideres solo nuestras palabras',
  subtitulo1: 'Nuestros clientes respaldan nuestro ',
  subtitulo2: 'compromiso',
  testimonios: [
    {
      nombre: 'Julio Vargas Gonzalez',
      texto:
        'Hemos trabajado con software Audisoft hace ya muchos años el cual nos ha ayudado de gran manera en el manejo y resguardo de nuestra información financiera, contable y tributaria,  el cual nos ha permitido ser oportunos en la toma de decisiones, en nuestra experiencia con Audisoft podemos decir que siempre hemos sido escuchados con nuestras necesidades lo que hace que el sistema sea mucho más amigable a nuestros procesos administrativos, además están siempre al día con las nuevas disposiciones normativas, el soporte ha sido de fácil acceso y de pronto solución ante cualquier imprevisto, todo esto nos hace mantener nuestra confianza en Audisoft',
      imagen: test01,
      video: '',
    },
    {
      nombre: 'Jocelyn Cáceres Fuentealba',
      texto:
        'Perfomaq destaca enormemente el servicio prestado por Audisoft, el cuál nos ha permitido cumplir satisfactoriamente con nuestras operaciones contables y financieras  de manera eficiente, además de recibir una asistencia de soporte técnico de alto nivel que acompaña en el buen funcionamiento del sistema.',
      imagen: test02,
      video: '',
    },
    {
      nombre: 'Natalia Jiménez',
      texto:
        'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est',
      imagen: test03,
      video: video1,
    },
    {
      nombre: 'Natalia Jiménez',
      texto:
        'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est',
      imagen: test04,
      video: video2,
    },
  ],
}

export default compromiso
