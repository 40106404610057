import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import Home from './pages/Home/Home'
import Nosotros from './pages/Nosotros/Nosotros'
import Planes from './pages/Planes/Planes'
import Simular from './pages/Simular/Simular'
import Contacto from './pages/Contacto/Contacto'

import ERP from './pages/Productos/ERP'
import PDV from './pages/Productos/PDV'
import Contador from './pages/Productos/Contador'
import Tributario from './pages/Productos/Tributario'

export default function Routes() {
  const NotMatch = () => {
    return <Redirect to="/" />
  }

  return (
    <Switch>
      <Route path="/" exact render={({ match }) => <Home match={match} />} />
      <Route path="/Nosotros" exact render={({ match }) => <Nosotros match={match} />} />
      <Route path="/Productos/ERP" exact render={({ match }) => <ERP match={match} />} />
      <Route path="/Productos/PDV" exact render={({ match }) => <PDV match={match} />} />
      <Route path="/Productos/contador" exact render={({ match }) => <Contador match={match} />} />
      <Route path="/Productos/tributario" exact render={({ match }) => <Tributario match={match} />} />
      <Route path="/Planes" exact render={({ match }) => <Planes match={match} />} />
      <Route path="/Simular-cotizacion" exact render={({ match }) => <Simular match={match} />} />
      <Route path="/Contacto" exact render={({ match }) => <Contacto match={match} />} />
      <Route component={NotMatch} />
    </Switch>
  )
}
