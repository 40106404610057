import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  GridItem,
  HStack,
  Text,
  List,
  ListItem,
  ListIcon,
} from '@chakra-ui/react';
import { FaCheckCircle } from 'react-icons/fa';

import contador from '../../administracion/jsons_images/Shared/Planes/contador'
import empresas from '../../administracion/jsons_images/Shared/Planes/empresas'
import punto from '../../administracion/jsons_images/Shared/Planes/punto'


export default function Planes({ ...props }) {
  const { isComponent } = props
  const history = useHistory();
  const [option, setOption] = useState("empresas");
  const [planSelected, setPlanSelected] = useState(empresas);

  const handlePlan = (plan) => {
    setOption(plan)
    if (plan === "empresas") {
      setPlanSelected(empresas)
    } else if (plan === "punto") {
      setPlanSelected(punto)
    } else {
      setPlanSelected(contador)
    }
  }

  const handleNav = (plan) => {
    window.localStorage.setItem("plan", plan);
    history.push("/Contacto/")
  }

  return (
    <Grid
      pt="5%"
      // bg={!isComponent && `url(${planes_bg}) no-repeat`}
      bgSize={{ base: "cover" }}
    >
      <GridItem
        pt={{ lg: "4%", base: "15%" }}
        m="auto"
        alignItems="center"
        mx={{ base: isComponent && "1px", md: "auto" }}
      >
        <Grid
          display="inline"
          float={"center"}
          m="auto"
        >
          <Text as="span" fontSize="30px" textAlign="center"> Conoce nuestros </Text>
          <Text as="span" fontSize="30px" fontWeight={"bold"}> principales </Text>
          <Text as="span" fontSize="30px" fontWeight={"bold"} backgroundColor="#BEE625" pr="30px">planes </Text>
        </Grid>
        {/* buttongroup */}
        <Grid
          pt="5%"
          m={{ md: "auto" }}
        >
          <ButtonGroup
            border="2px"
            borderRadius="27px"
            borderColor="#B2D237"
            textAlign="center"
            py="3px"
            m={{ md: "auto" }}
          >
            <Button
              bg="white"
              onClick={() => handlePlan("contador")}
              borderRadius="27px"
              fontSize="14px"
              fontWeight={option === "contador" ? 600 : 100}
              backgroundColor={option === "contador" && "#B2D237"} color={option === "contador" && "white"}
              _hover={{
                textDecoration: 'none',
                bg: "#7a9413",
              }}
            >
              Contador
            </Button>
            <Button
              bg="white"
              onClick={() => handlePlan("empresas")}
              borderRadius="27px"
              fontSize="14px"
              fontWeight={option === "empresas" ? 600 : 100}
              backgroundColor={option === "empresas" ? "#B2D237" : "white"} color={option === "empresas" && "white"}
              _hover={{
                textDecoration: 'none',
                bg: "#7a9413",
              }}
            >
              Empresas
            </Button>
            <Button
              bg="white"
              onClick={() => handlePlan("punto")}
              borderRadius="27px"
              fontSize="14px"
              fontWeight={option === "punto" ? 600 : 100}
              backgroundColor={option === "punto" && "#B2D237"} color={option === "punto" && "white"}
              _hover={{
                textDecoration: 'none',
                bg: "#7a9413",
              }}
            >
              Punto de venta
            </Button>
          </ButtonGroup>
        </Grid>
      </GridItem>

      <Grid w={{ md: "90%", lg: "80%" }} m="auto">
        <Grid
          m="auto"
          w={{ md: "80%", base: "85%" }}
          pt="3%"
        >
          <Grid
            templateColumns={{ base: "repeat(1, 1fr)", sm: "repeat(1, 1fr)", md: "repeat(3, 1fr)", lg: "repeat(3, 1fr)" }}
            background={{
              md: isComponent &&
                "radial-gradient(circle, rgba(231,255,211,1) 28%, rgba(255,255,255,1) 54%)"
            }}
            gap={{ base: 1 }}
          >
            {/* basic  */}
            <Grid
              borderWidth="1px"
              borderColor={"#BEE625"}
              borderRadius={'xl'}
              bgColor="white"
              w="90%"
              flexDirection="column"
              py="5%"
              boxShadow="0px 1px 8px 1px #E7FFD3"
            >
              <Box
                py="3px"
                textAlign="center"
                justify="center"
                px="12%"
              >
                <Text fontWeight={600} fontSize={option !== "punto" ? "35px" : "25px"} color="#A5C918">
                  {planSelected.basic.titulo}
                </Text>
                <HStack
                  justifyContent="center"
                  display="column"
                >
                  <Text fontSize="16px" fontWeight="600" color="#B7B7B7">
                    {planSelected.basic.uf}
                  </Text>
                  <Text fontSize="12px" fontWeight="600" color="#B7B7B7">
                    Mensual
                  </Text>
                  <Text fontSize="10px" fontWeight="900" borderRadius="13px" backgroundColor="#A5C918" color="white">
                    {planSelected.basic.oferta}
                  </Text>
                  <Text fontSize="36px" fontWeight="900" color="#A5C918">
                    {planSelected.basic.valor}
                  </Text>
                  <Text fontSize="12px" fontWeight="900" color="#A5C918">
                    {planSelected.basic.perYear}
                  </Text>
                </HStack>
              </Box>
              <Grid pl="7%" py="7%">
                <List textAlign="left" >
                  {planSelected.basic.contenido.map((basic, index) => (
                    <ListItem key={`basic-${index}`} fontSize={"12px"}>
                      <ListIcon as={FaCheckCircle} color="#B2D237" />
                      {basic}
                    </ListItem>
                  ))}
                </List>
              </Grid>

              <Button
                w="110px"
                borderRadius="15px"
                borderColor="#A5C918"
                variant="outline"
                mx="auto"
                mt="auto"
                onClick={() => handleNav(planSelected.basic.titulo)}
              >
                <Text color="#B2D237">
                  Me interesa
                </Text>
              </Button>

            </Grid>
            {/* full  */}
            <Grid
              borderWidth="1px"
              borderColor={"#BEE625"}
              borderRadius={'xl'}
              bgColor="white"
              w="90%"
              flexDirection="column"
              py="5%"
              boxShadow="0px 1px 8px 1px #E7FFD3"
            >
              <Box
                py="3px"
                textAlign="center"
                justify="center"
                px="12%"
              >

                <Text fontWeight={600} fontSize={option !== "punto" ? "35px" : "25px"} color="#A5C918">
                  {planSelected.full.titulo}
                </Text>
                <HStack justifyContent="center" display="column">
                  <Text fontSize="16px" fontWeight="600" color="#B7B7B7">
                    {planSelected.full.uf}
                  </Text>
                  <Text fontSize="12px" fontWeight="600" color="#B7B7B7">
                    Mensual
                  </Text>
                  <Text fontSize="10px" fontWeight="900" borderRadius="13px" backgroundColor="#A5C918" color="white">
                    {planSelected.full.oferta}
                  </Text>
                  <Text fontSize="36px" fontWeight="900" color="#A5C918">
                    {planSelected.full.valor}
                  </Text>
                  <Text fontSize="12px" fontWeight="900" color="#A5C918">
                    {planSelected.full.perYear}
                  </Text>
                </HStack>
              </Box>
              <Grid pl="7%" py="7%">
                <List textAlign="left" >
                  {planSelected.full.contenido.map((full, index) => (
                    <ListItem key={`full-${index}`} fontSize={"12px"}>
                      <ListIcon as={FaCheckCircle} color="#B2D237" />
                      {full}
                    </ListItem>
                  ))}
                </List>
              </Grid>
              <Button
                w="110px"
                borderRadius="15px"
                borderColor="#A5C918"
                variant="outline"
                mx="auto"
                mt="auto"
                onClick={() => handleNav(planSelected.full.titulo)}
              >
                <Text color="#B2D237">
                  Me interesa
                </Text>
              </Button>
            </Grid>
            {/* expert  */}
            <Grid
              borderWidth="1px"
              borderColor={"#BEE625"}
              borderRadius={'xl'}
              bgColor="white"
              w="90%"
              flexDirection="column"
              py="5%"
              boxShadow="0px 1px 8px 1px #E7FFD3"
            >
              <Box
                py="3px"
                textAlign="center"
                justify="center"
                px="12%"
              >
                <Text fontWeight={600} fontSize={option !== "punto" ? "35px" : "25px"} color="#A5C918">
                  {planSelected.expert.titulo}
                </Text>
                <HStack justifyContent="center" display="column">
                  <Text fontSize="16px" fontWeight="600" color="#B7B7B7">
                    {planSelected.expert.uf}
                  </Text>
                  <Text fontSize="12px" fontWeight="600" color="#B7B7B7">
                    Mensual
                  </Text>
                  <Text fontSize="10px" fontWeight="900" borderRadius="13px" backgroundColor="#A5C918" color="white">
                    {planSelected.expert.oferta}
                  </Text>
                  <Text fontSize="36px" fontWeight="900" color="#A5C918">
                    {planSelected.expert.valor}
                  </Text>
                  <Text fontSize="12px" fontWeight="900" color="#A5C918">
                    {planSelected.expert.perYear}
                  </Text>
                </HStack>
              </Box>
              <Grid pl="7%" py="7%">
                <List textAlign="left" >
                  {planSelected.expert.contenido.map((expert, index) => (
                    <ListItem key={`expert-${index}`} fontSize={"12px"}>
                      <ListIcon as={FaCheckCircle} color="#B2D237" />
                      {expert}
                    </ListItem>
                  ))}
                </List>
              </Grid>
              <Button
                w="110px"
                borderRadius="15px"
                borderColor="#A5C918"
                variant="outline"
                mx="auto"
                mt="auto"
                onClick={() => handleNav(planSelected.expert.titulo)}
              >
                <Text color="#B2D237">
                  Me interesa
                </Text>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid >
    </Grid >
  );
}