import profile1 from './profile1.png'
import profile2 from './profile2.png'
import profile3 from './profile3.png'
import profile4 from './profile4.png'
import profile5 from './profile5.png'
import profile6 from './profile6.png'
import profile7 from './profile7.png'
import profile8 from './profile8.png'
import profile9 from './profile9.png'
import profile10 from './profile10.png'
import profile11 from './profile11.png'
import profile12 from './profile12.png'

const equipo = [
  {
    descripcion:
      'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et',
    foto: profile1,
    nombre: 'Juan',
    cargo: 'FUNDADOR',
  },
  {
    descripcion:
      'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et',
    foto: profile2,
    nombre: 'Juan',
    cargo: 'FUNDADOR',
  },
  {
    descripcion:
      'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et',
    foto: profile3,
    nombre: 'Juan',
    cargo: 'FUNDADOR',
  },
  {
    descripcion:
      'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et',
    foto: profile4,
    nombre: 'Juan',
    cargo: 'FUNDADOR',
  },
  {
    descripcion:
      'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et',
    foto: profile5,
    nombre: 'Juan',
    cargo: 'FUNDADOR',
  },
  {
    descripcion:
      'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et',
    foto: profile6,
    nombre: 'Juan',
    cargo: 'FUNDADOR',
  },
  {
    descripcion:
      'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et',
    foto: profile7,
    nombre: 'Juan',
    cargo: 'FUNDADOR',
  },
  {
    descripcion:
      'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et',
    foto: profile8,
    nombre: 'Juan',
    cargo: 'FUNDADOR',
  },
  {
    descripcion:
      'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et',
    foto: profile9,
    nombre: 'Juan',
    cargo: 'FUNDADOR',
  },
  {
    descripcion:
      'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et',
    foto: profile10,
    nombre: 'Juan',
    cargo: 'FUNDADOR',
  },
  {
    descripcion:
      'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et',
    foto: profile11,
    nombre: 'Juan',
    cargo: 'FUNDADOR',
  },
  {
    descripcion:
      'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et',
    foto: profile12,
    nombre: 'Juan',
    cargo: 'FUNDADOR',
  },
]

export default equipo
