import React from "react";
import {
  Grid,
  GridItem,
  Text,
} from '@chakra-ui/react';
import parse from 'html-react-parser';

import Tributario from "../../../administracion/jsons_images/Productos/Tributario/Tributario"

export default function Header() {
  return (
    <Grid
      bgImage={{
        md: Tributario.bg_w,
        base: Tributario.bg_m
      }}
      // bgSize="100% 100%"
      bgSize={"cover"}
      backgroundRepeat='no-repeat'
      pl={{ md: "15%", base: "7%" }}
      pt={{ base: '500px', md: '200px' }}
      pb={{ base: '50px', md: '200px' }}
    >
      <GridItem
        display="inline"
        float="center"
      >
        <Text fontSize="40px" fontWeight={"bold"} as={"mark"} bgColor="#B2D237" pl="15px" pr="15px">
          {Tributario.titulo1}
        </Text>
        <Text fontSize="40px" fontWeight={"bold"} pl="15px" pr="15px">
          {Tributario.titulo2}
        </Text>
        <Text fontSize="30px" pl="15px" pr="15px" w={{ lg: "30%" }}>
          {Tributario.titulo3}
        </Text>
      </GridItem>

      <GridItem
        w={{ lg: "35%", base: "80%" }}
      >
        <Text fontSize="12px" pl="15px">
          {parse(Tributario.subtitulo)}
        </Text>
      </GridItem>
    </Grid >
  );
}
