import bg_m from './trib_m_bg.png'
import bg_w from './trib_w_bg.png'

import trib01 from './trib01.png'

const trib = {
  bg_w: bg_w,
  bg_m: bg_m,
  titulo1: 'Los más',
  titulo2: 'experimentados',
  titulo3: 'jamás descuidan el cumplimiento tributario',
  subtitulo:
    'Controla y cumple con las obligaciones tributarias con el único software que se mantiene a la vanguardia de las actualizaciones tributarias, operación IVA, operación Renta y Declaraciones Juradas. ¡Se integra a la perfección con  <strong> ERP o Contador! </strong>',
  modulos: {
    titulo1: 'Algunos de sus',
    titulo2: 'módulos',
    contenido: [
      {
        titulo: 'Formulario 29',
        texto:
          'El sistema es capaz de leer los datos registrados en los módulos de compras, ventas, activo fijo y remuneraciones. Con ellos construye el formulario 29. En caso de existir remanente, el sistema guarda los datos en UTM para recuperar los valores actualizados en el periodo siguiente, al finalizar el proceso, el sistema genera el archivo para cargar en portal del SII.',
        imagen: '',
      },
      {
        titulo: 'Generación y envío de declaraciones juradas',
        texto:
          'El sistema genera las declaraciones juradas con la información que posee de la contabilidad y posterior configuración del árbol tributario. <strong> Las declaraciones juradas que se pueden generar son: </strong>',
        imagen: '',
      },
    ],
  },
  tabla: [
    [1822, 1835, 1836, 1837, 1839, 1842],
    [1843, 1847, 1866, 1867, 1879, 1887],
    [1897, 1904, 1907, 1909, 1926, 1937],
    [1943, 1946, 1947, 1948, '', ''],
  ],
  otros: [
    {
      titulo: 'Informes disponibles',
      contenido: [
        '• Clasificación de cuentas según árbol tributario',
        '• Capital Propio Tributario y Financiero',
        '• Registro de Utilidades • Renta líquida Imponible',
        '• Registro de Rentas Empresariales',
        '• Formulario 22',
        '• Conciliación Tributaria',
        '• Declaraciones Juradas',
      ],
    },
    {
      titulo: 'Genera certificados asociados a declaraciones juradas',
      contenido: [
        ' • Honorarios',
        '• Sueldos',
        '• Retiros 14A',
        '• Renta Atribuida',
        '• Rentas Presuntas Atribuidas',
      ],
    },
  ],
  imagen: trib01,
}

export default trib
