import React from "react";
import {
  Grid,
  GridItem,
  Text,
  Image,
} from '@chakra-ui/react';

import Tributario from "../../../administracion/jsons_images/Productos/Tributario/Tributario"

export default function Modulos() {

  return (
    <Grid
      pr={{ base: "2%", lg: "35%" }}
      pb={{ base: "10%", lg: "20%" }}
    >
      <Grid
        background="linear-gradient(94deg, rgba(245,245,247,1) 0%, rgba(252,255,239,1) 57%)"
        borderRadius={"0px 0px 264px 0px"}
        templateColumns={{ base: "repeat(1, 1fr)", sm: "repeat(1, 1fr)", md: "repeat(3, 1fr)", lg: "repeat(3, 1fr)" }}
        templateRows={{ base: "repeat(2, 1fr)", sm: "repeat(2, 1fr)", md: "repeat(1, 1fr)", lg: "repeat(1, 1fr)" }}
        gap={5}
        py="5%"
        pl={{ base: "10%", lg: "35%" }}
      >
        <GridItem
          colSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
          rowSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
        >
          <Text fontSize="16px" fontWeight="bold" color="#A5C918">{Tributario.otros[0].titulo}</Text>
          {Tributario.otros[0].contenido.map((inf, index) => (
            <Text key={index} fontSize={"12px"}>{inf}</Text>
          ))}
        </GridItem>

        <GridItem
          colSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
          rowSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
        >
          <Text fontSize="16px" fontWeight="bold" color="#A5C918">{Tributario.otros[1].titulo}</Text>
          <Text fontSize="12px"> Los certificados disponibles son:</Text>
          {Tributario.otros[1].contenido.map((cert, index) => (
            <Text key={index} fontSize={"12px"}>{cert}</Text>
          ))}
        </GridItem>
        <GridItem
          colSpan={{ base: 0, sm: 0, md: 1, lg: 1 }}
          rowSpan={{ base: 0, sm: 0, md: 1, lg: 1 }}
          display={{ base: "none", md: 'block' }}
        >
          <Image
            src={Tributario.imagen}
            position="absolute"
            w="30%"
            right={{ md: "3%", lg: "15%"}}
            alt="trib01"
          />
        </GridItem>
      </Grid >
      <GridItem
        display={{ md: 'none' }}
      >
        <Image
          src={Tributario.imagen}
          w="70%"
          m="auto"
          alt="trib01"
        />
      </GridItem>
    </Grid >
  );
}
