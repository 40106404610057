const contador = {
  basic: {
    titulo: 'Contador Basic',
    uf: '3 UF',
    title: 'Mensual',
    oferta: 'Mejor oferta 20% descuento',
    valor: '2,4',
    perYear: 'Al mes x año',
    contenido: [
      '3 Usuarios',
      'Empresas Ilimitadas',
      'Contabilidad',
      'Remuneraciones',
      'IVA',
      'Financiero',
    ],
  },
  full: {
    titulo: 'Contador Full',
    uf: '5,7 UF',
    title: 'Mensual',
    oferta: 'Mejor oferta 20% descuento',
    valor: '4,6 UF',
    perYear: 'Al mes x año',
    contenido: [
      '3 Usuarios',
      'Empresas Ilimitadas',
      'Contabilidad',
      'Remuneraciones',
      'IVA',
      'Financiero',
      '1 Empresa con emisión de DTE',
      'Software Audisoft Tributario con 1 usuario y empresas ilimitadas',
    ],
  },
  expert: {
    titulo: 'Contador Expert',
    uf: '8 UF',
    title: 'Mensual',
    oferta: 'Mejor oferta 20% descuento',
    valor: '6,4 UF',
    perYear: 'Al mes x año',
    contenido: [
      '3 Usuarios',
      'Empresas Ilimitadas',
      'Contabilidad',
      'Remuneraciones',
      'IVA',
      'Financiero',
      '1 Empresa con emisión de DTE',
      '1 Empresa con LCE',
      '1 Empresa con LRE',
      'Software Audisoft Tributario Avanzado con 1 usuario y empresas ilimitadas',
    ],
  },
}

export default contador
