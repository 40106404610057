import React from "react";
import {
  Grid,
  GridItem,
  Text,
  Image,
  // Box,
  // Center,
  SimpleGrid,
} from '@chakra-ui/react';

import equipo from "../../administracion/jsons_images/Nosotros/Equipo/equipo"
import equipo_bg from "../../administracion/jsons_images/Nosotros/Equipo//equipo_bg.svg"
import "./equipo.css"

export default function Equipo() {
  // const [isVisible, setIsVisible] = React.useState({});

  // const over = (position) => {
  //   setIsVisible({
  //     ...isVisible,
  //     [position]: true
  //   })
  // }

  // const out = () => {
  //   setIsVisible({})
  // }

  return (
    <Grid
      bg={`url(${equipo_bg}) no-repeat`}
      bgSize="cover"
    >
      <Grid m="auto" w="80%">
        <Grid
          display="inline"
          float="center"
          m="auto"
          py={{ base: "15px", md: "60px" }}
        >
          <Text as="span" fontSize="30px" fontWeight={"bold"}>Nuestro </Text>
          <Text as="span" fontSize="30px" fontWeight={"bold"} pr="30px" bgColor="#BEE625">equipo</Text>
        </Grid>
        <SimpleGrid
          columns={12}
          m="auto"
          spacing={{ base: 2, md: 9 }}
          w={{ base: "100%", lg: "70%", xl: "75%" }}
        >
          {equipo.map((eq, index) =>
            <GridItem
              colSpan={{ base: 6, lg: 4, xl: 3 }}
              key={index}
            >
              <div className="wrapper">
                <Image
                  src={eq.foto}
                  borderRadius={"50%"}
                />
                <div className="overlay" >
                  <div className="content">
                    <Text fontSize={{ base: "8px", md: "10px" }} noOfLines={8} >
                      {eq.descripcion}
                    </Text>
                  </div>
                </div>
              </div>

              <Text textAlign={"center"} fontSize={{ base: "16px", md: "20px" }} my="8px">{eq.nombre}</Text>
              <Text textAlign={"center"} fontSize={{ base: "12px", md: "14px" }} mt="2px" pb="40px" fontWeight={"bold"}>{eq.cargo}</Text>
            </GridItem>
          )}
        </SimpleGrid>
      </Grid>
    </Grid>
  );
}
