import React from 'react'
import { ChakraProvider } from '@chakra-ui/react'

// import '@fontsource/poppins'
// import '@fontsource/poppins/700.css' // Weight 700.

import Routes from './Routes'
import Whatsapp from '../src/components/Navigation/Whatsapp'
import Chat from '../src/components/Navigation/Chat'
import NavBar from '../src/components/Navigation/NavBar'
import Footer from '../src/components/Navigation/Footer'

import theme from './utils/themeProvider'

function App() {
  return (
    <ChakraProvider theme={theme}>
      <NavBar />
      <div>
        <Routes />
        <Footer />
      </div>
      <Whatsapp />
      <Chat />
    </ChakraProvider>
  )
}

export default App
