import React, { useState } from "react";
import {
  Box,
  Container,
  IconButton,
  GridItem,
  Grid,
  Image,
  Text,
  ScaleFade,
} from '@chakra-ui/react';
import { FaPlay } from 'react-icons/fa';

import ModalWatchVideo from "../Shared/ModalWatchVideo"
import compromiso from "../../administracion/jsons_images/Home/Compromiso/compromiso"

export default function Compromiso() {
  const [urlVideo, setUrlVideo] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [showLess, setShowLess] = useState({});

  const handleOpenVideo = (url) => {
    setUrlVideo(url)
    setIsOpen(true)
  }

  const handleViewMore = (position) => {
    let newValuve = showLess[position];
    setShowLess({
      ...showLess,
      [position]: !newValuve
    })
  }

  function TestimoniosComponent({ contenido, index }) {
    return (
      <Box
        bg="linear-gradient(185deg, rgba(245,245,247,1) 32%, rgba(247,255,213,1) 100%)"
        boxShadow="5px 10px #FCFFEF"
        border="1px"
        borderRadius="24px"
        borderColor="#BEE625"
        p="2%"
      >
        <Grid >
          <Image
            m="auto"
            borderRadius="50%"
            w="80%"
            src={contenido.imagen}
            p="5%"
            alt="testimonio_3"
          />
          {contenido.video &&
            <IconButton
              icon={<FaPlay color="white" />}
              isRound="true"
              backgroundColor="#BEE625"
              onClick={() => handleOpenVideo(contenido.video)}
              m="auto"
              position="relative"
            >
            </IconButton>
          }
        </Grid>
        <Grid p="3%">
          <Text fontSize="12px" textAlign="center">
            {showLess[index] ? contenido.texto : contenido.texto.slice(0, 100)}
          </Text>
          <Text
            fontSize="12px"
            style={{ cursor: "pointer" }}
            onClick={() => handleViewMore(index)}
            fontWeight="bold"
            m="auto"
          >
            {showLess[index] ? "ver menos" : "..."}
          </Text>
          <Text fontSize="12px" fontWeight={800} textAlign="center" >
            {contenido.nombre}
          </Text>
        </Grid>
      </Box>
    )
  }

  return (
    <Grid
      px={{ md: "20%", base: "3%" }}
      pt="3%"
    >
      <ModalWatchVideo isOpen={isOpen} setIsOpen={setIsOpen} urlVideo={urlVideo} />
      <Grid
        templateColumns={{ base: "repeat(2, 1fr)", sm: "repeat(2, 1fr)", md: "repeat(3, 1fr)", lg: "repeat(3, 1fr)" }}
        templateRows={{ base: "repeat(3, 1fr)", sm: "repeat(3, 1fr)", md: "repeat(2, 1fr)", lg: "repeat(2, 1fr)" }}
        gap={3}
      >
        <GridItem
          colSpan={{ base: 2, sm: 2, md: 2, lg: 2 }}
          rowSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
          m="auto"
        >
          <Container>
            <Text fontSize="16px">
              {compromiso.titulo}
            </Text>
            <Text as="span" fontSize="30px" fontWeight={800} pl="15px" >
              {compromiso.subtitulo1}
            </Text>
            <Text fontSize="30px" as={"mark"} fontWeight={800} bgColor="#BEE625" >
              {compromiso.subtitulo2}
            </Text>
          </Container>
        </GridItem>
        {compromiso.testimonios.map((item, index) => (
          <GridItem
            key={index}
            colSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
            rowSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
          >
            <ScaleFade initialScale={0.9} in={true} whileHover={{ scale: 1.1 }}>
              <TestimoniosComponent contenido={item} index={index} />
            </ScaleFade>
          </GridItem>
        ))}
      </Grid>
    </Grid >
  );
}
