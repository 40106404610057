import React from "react";
import {
  Grid,
  GridItem,
  Box,
  Text,
  ScaleFade,
} from '@chakra-ui/react';
import parse from 'html-react-parser';

import Tributario from "../../../administracion/jsons_images/Productos/Tributario/Tributario"
import tableStyle from "./table.module.css";

export default function Modulos() {

  return (
    <Grid
      m={{ base: "auto", lg: "15px" }}
      py="25px"
    >
      <GridItem
        display="inline"
        float="center"
        m="auto"
        textAlign="center"
        pb="5%"
      >
        <Text as="span" fontSize="30px" fontWeight={800}>
          {Tributario.modulos.titulo1}
        </Text>
        <Text fontSize="30px" fontWeight={800} pl="5px" pr="30px" as={"mark"} bgColor="#BEE625">
          {parse(Tributario.modulos.titulo2)}
        </Text>
      </GridItem>
      <GridItem
        margin="auto"
      >
        <Grid
          textAlign="center"
          w={{ base: "100%", lg: "50%" }}
          margin="auto"
        >
          <Text fontSize="16px" fontWeight="bold" color="#A5C918">
            {Tributario.modulos.contenido[0].titulo}
          </Text>
          <Text fontSize="12px" m="auto">
            {parse(Tributario.modulos.contenido[0].texto)}
          </Text>
        </Grid>
        <Grid
          textAlign="center"
          w={{ base: "100%", lg: "50%" }}
          m="auto"
          pt="5%"
        >
          <Text fontSize="16px" fontWeight="bold" color="#A5C918" w="70%" m="auto">
            {Tributario.modulos.contenido[1].titulo}
          </Text>
          <Text fontSize="12px" w="90%">
            {parse(Tributario.modulos.contenido[1].texto)}
          </Text>
        </Grid>
      </GridItem>
      <GridItem
        pt="5%"
        margin="auto"
      >
        <Box
          border="2px"
          borderRadius="27px"
          borderColor="#B2D237"
          textAlign="center"
          p={{ md: '1%' }}
          boxShadow="0px 1px 8px 1px #E7FFD3"
        >
          <ScaleFade initialScale={0.9} in={true} whileHover={{ scale: .9 }}>
            <table className={tableStyle}>
              <tbody>
                {Tributario.tabla.map((x) => (
                  <tr>
                    {x.map((y) => (
                      <td style={{ textAlign: 'center', color: 'grey' }}>{y}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </ScaleFade>
        </Box>
      </GridItem>
    </Grid>
  )
}
