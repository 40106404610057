import React from "react";
import {
  Grid,
} from '@chakra-ui/react';

import Conocenos from "../../components/Nosotros/Conocenos"
import Equipo from "../../components/Nosotros/Equipo"


export default function Nosotros() {

  return (
    <Grid >
      <Conocenos />
      <Equipo />
    </Grid >
  );
}
