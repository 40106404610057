import React from "react";
import { animateScroll as scroll } from "react-scroll";
import { Grid } from '@chakra-ui/react';

import Slider from "../../components/Home/Slider"
import Herramientas from "../../components/Home/Herramientas"
import Diferencia from "../../components/Home/Diferencia"
import Productividad from "../../components/Home/Productividad"
import Acomodamos from "../../components/Home/Acomodamos"
import Planes from "../../components/Shared/Planes"
import Confian from "../../components/Home/Confian"
import Compromiso from "../../components/Home/Compromiso"
import PreguntasFrecuentes from "../../components/Shared/PreguntasFrecuentes"

export default function Home() {
  React.useEffect(() => {
    scroll.scrollToTop();
  });
  React.useEffect(() => {
    window.localStorage.removeItem("plan");
  }, [])

  return (
    <Grid >
      <Slider />
      <Herramientas />
      <Diferencia />
      <Productividad />
      <Acomodamos />
      <Planes isComponent={true} />
      <Confian />
      <Compromiso />
      <PreguntasFrecuentes />
    </Grid >
  );
}
