import React from "react";
import {
  IconButton,
  GridItem,
  Grid,
  Flex,
  Text,
} from '@chakra-ui/react';
import { FaPlus, FaMinus } from 'react-icons/fa';

import preguntasFrecuentes from '../../administracion/jsons_images/Shared/PreguntasFrecuentes/preguntasFrecuentes'

export default function PreguntasFrecuentes() {
  const [open, setOpen] = React.useState({});

  const handleOpen = (value) => {
    let newValuve = open[value];
    setOpen({ ...open, [value]: !newValuve })
  }

  return (
    <Grid
      pt="15%"
      pl={{ lg: "20%", base: "10%" }}
      pr={{ lg: "20%", base: "10%" }}
      pb={"10%"}
    >
      <GridItem m="auto">
        <Grid display="inline" float="center">
          <Text as="span" fontSize="30px" fontWeight={"bold"} pl="30px" bgColor="#BEE625"> Preguntas </Text>
          <Text as="span" fontSize="30px" fontWeight={"bold"}> frecuentes</Text>
        </Grid>
      </GridItem>

      <Grid pt="5%" gap={6}>
        {preguntasFrecuentes.map((item, index) =>
          <Flex key={index} >
            <Grid w="70%">
              <Text fontWeight={800}>
                {item.pregunta}
              </Text>
              {open[index] &&
                <Text>
                  {item.respuesta}
                </Text>
              }
            </Grid>
            <Grid position="absolute" right={{ lg: "20%", base: "10%" }}>
              <IconButton onClick={() => handleOpen(index)} icon={!open[index] ? <FaPlus color="black" /> : <FaMinus color="black" />} variant="outlined">
              </IconButton>
            </Grid>
          </Flex>
        )}
      </Grid >
    </Grid >
  );
}
